import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { useQuery, useRedux, useStateModal } from "hooks";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { HistoryPayload } from "./ListView";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Header } from "../subcomponents/Header";
import { UnitHistory } from "./subcomponents/UnitHistory";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useUnitColumns } from "./useUnitColumns";
import {
  ManufacturingUnitGroupPriority,
  ManufacturingUnitListViewItem,
} from "api/manufacturing/units/models";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "./ListView.module.css";
import { cx, queryString } from "utilities";
import { RightPanel } from "./rightPanel/RightPanel";
import { StagesTableToolbar } from "./stagesTableToolbar/StagesTableToolbar";
import { useEffect } from "react";

export const AllManufacturingUnitsList = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });

  const search = queryString.stringify(query);
  const {
    data: units,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingUnitsActions.useGetUnitBoardList(search);
  const historyModal = useStateModal<HistoryPayload>();
  const [dispatch, { ui }] = useRedux();

  const stageListMultiSelect = useTableMultiSelect({ rows: units });

  const tableProps = useTableFeatureConnector({
    rows: units,
    withDrawer: "manufacturingStageNormalList",
    withMultiSelect: stageListMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  useEffect(() => {
    dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "list" }));
  }, [dispatch, ui]);

  const columns = useUnitColumns(units, historyModal);

  return (
    <PageWrapper>
      <Header isListOfAllUnits />
      <div className="d-flex flex-column flex-1 overflow-hidden gap-2">
        <Table<ManufacturingUnitListViewItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              row: row => {
                return {
                  className: cx(tableProps?.overrides?.().row?.(row).className, {
                    [styles.criticalRow]:
                      row.manufacturingItem.priority === ManufacturingUnitGroupPriority.A,
                  }),
                };
              },
            };
          }}
        />
        <RightPanel />
        <StagesTableToolbar
          close={stageListMultiSelect.clear}
          hideStatusChange
          numberOfSelectedItems={stageListMultiSelect.selectedRows.length}
          units={stageListMultiSelect.selectedRows}
        />
      </div>
      {historyModal.isOpen && <UnitHistory close={historyModal.close} state={historyModal.state} />}
    </PageWrapper>
  );
});
