import { createApiQuery } from "hooks/createApiQuery";
import { manufacturingUnitsApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import {
  GroupManufacturingItemPayload,
  ManufacturingGroup,
  ManufacturingUnitListViewDetails,
} from "./models";
import { assertIsDefined } from "utilities/assertIsDefined";
import immer from "immer";
import { UUID } from "api/types";
import { PartialOf } from "typeUtilities";
import { useQuery } from "hooks";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";

const useGetManufacturingUnitsGroups = createPaginatedApiQuery(
  manufacturingUnitsApi.getManufacturingUnitsGroups,
);

const useCustomOrderedMaterials = createApiQuery(
  manufacturingUnitsApi.getManufacturingUnitsCustomOrderedMaterials,
);

const useGetManufacturingUnitGroupDetails = createApiQuery(
  manufacturingUnitsApi.getManufacturingUnitsGroupsDetails,
);

const useGetUnitItem = createApiQuery(manufacturingUnitsApi.getUnitItem);

const useGetGroupedItems = createPaginatedApiQuery(manufacturingUnitsApi.getGroupedItems);

const useGetUnitItems = createPaginatedApiQuery(manufacturingUnitsApi.getUnitItems);

const useGetUnitBoardList = createPaginatedApiQuery(manufacturingUnitsApi.getUnitBoardList);

const useGetManufacturingGroups = createApiQuery(manufacturingUnitsApi.getManufacturingGroups);

const useStatistics = createApiQuery(manufacturingUnitsApi.getStatistics);

const useListUnitDetails = createApiQuery(manufacturingUnitsApi.getListUnit);

const useBulkPatchPriorityManufacturingUnits = (
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>,
) => {
  return useMutation(
    manufacturingUnitsApi.patchBulkManufacturingUnits,
    ({ queryClient, toastr }) => ({
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries();
        setGroupDetails?.(prevDetails => {
          assertIsDefined(prevDetails);
          return immer(prevDetails, draft => {
            draft.elements = prevDetails.elements.map(item => {
              if (variables.ids.some(checkedId => checkedId === item.id))
                return {
                  ...item,
                  priority: variables.priority ?? item.priority,
                };
              return item;
            });
          });
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useListUnitPatch = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: PartialOf<ManufacturingUnitListViewDetails> }) => {
      return manufacturingUnitsApi.patchListUnit(toUpdate, id);
    },
    ({ queryUtils, queryClient }) => queryUtils.invalidateOnSuccessAndHandleError,
  );
};

const usePatchUnitItem = () =>
  useMutation(
    manufacturingUnitsApi.patchUnitItem,
    ({ queryClient, queryUtils }) => queryUtils.invalidateOnSuccessAndHandleError,
  );

const useBulkUpdateManufacturingUnitsList = () => {
  return useMutation(
    manufacturingUnitsApi.postBulkManufacturingUnitsList,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useGroupManufacturingUnits = (
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>,
) => {
  return useMutation(
    (data: GroupManufacturingItemPayload) => {
      const getUniqueAttributes = (
        attributes: GroupManufacturingItemPayload["attributes"],
      ): GroupManufacturingItemPayload["attributes"] => {
        const uniqueAttributes = new Set();
        return attributes.filter(attribute => {
          const stringifiedAttribute = JSON.stringify(attribute);
          return uniqueAttributes.has(stringifiedAttribute)
            ? false
            : uniqueAttributes.add(stringifiedAttribute);
        });
      };
      return manufacturingUnitsApi.postGroup({
        ...data,
        attributes: getUniqueAttributes(data.attributes),
      });
    },
    ({ queryClient, toastr }) => ({
      onSuccess: (_, args) => {
        setGroupDetails?.(prevGroups => {
          assertIsDefined(prevGroups);
          return {
            ...prevGroups,
            elements: prevGroups.elements.filter(
              unit => !args.manufacturingWorkingUnitIds.includes(unit.id),
            ),
          };
        });
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zgrupowano i przeniesiono do 'w trakcie'",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const usePostGroup = () => {
  return useMutation(manufacturingUnitsApi.postGroup, ({ queryClient, toastr }) => ({
    onSuccess: (_, args) => {
      queryClient.invalidateQueries();
    },
    onError: (error, args, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCancelManufacturingItem = (close?: () => void) => {
  return useMutation(
    manufacturingUnitsApi.postManufacturingUnitCancel,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close?.();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useSetAsFinished = () => {
  const { updateQuery } = useQuery();

  return useMutation(manufacturingUnitsApi.postSetAsFinished, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      updateQuery({ unitPanelId: "" });
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Przeniesiono do 'gotowe'",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useDownloadManufacturingItemPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  async function handleDownloadManufacturingItemPdf(
    manufacturingItemIds: UUID[],
    signature: string,
  ) {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = manufacturingFileFactory.manufacturingItemPdf(
      manufacturingItemIds,
      signature,
    );
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  }

  return handleDownloadManufacturingItemPdf;
};

const useBulkCancelManufacturingUnits = (
  close: () => void,
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>,
) => {
  return useMutation(
    manufacturingUnitsApi.patchBulkManufacturingUnits,
    ({ queryClient, toastr }) => ({
      onSuccess: (_, args) => {
        queryClient.invalidateQueries();
        close();
        setGroupDetails?.(prevDetails => {
          assertIsDefined(prevDetails);
          return immer(prevDetails, draft => {
            draft.elements.forEach(element => {
              if (args.ids.some(checkedId => checkedId === element.id)) element.isCancelled = true;
            });
          });
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

export const manufacturingUnitsActions = {
  useBulkPatchPriorityManufacturingUnits,
  useBulkUpdateManufacturingUnitsList,
  useCancelManufacturingItem,
  useCustomOrderedMaterials,
  useDownloadManufacturingItemPdf,
  usePostGroup,
  useGetGroupedItems,
  useGetManufacturingUnitsGroups,
  useGetManufacturingGroups,
  useGetManufacturingUnitGroupDetails,
  useGetUnitItem,
  useGroupManufacturingUnits,
  useListUnitPatch,
  useListUnitDetails,
  useStatistics,
  useSetAsFinished,
  usePatchUnitItem,
  useBulkCancelManufacturingUnits,
  useGetUnitBoardList,
  useGetUnitItems,
};
