import {
  GroupedUnitInProgress,
  ManufacturingGroup,
  ManufacturingUnitGroupPriority,
  ManufacturingUnitListViewItem,
  MaterialStageStatus,
  NormalizedGroupElement,
} from "api/manufacturing/units/models";
import { ISODateTime, QueryParams, UUID } from "api/types";
import { dateFns, queryString } from "./utilities";
import { AttributeCategory, BoardFormat } from "api/manufacturing/schemas/models";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { isAfter, isBefore, isSameDay, parseISO } from "date-fns";
import { StageBoardAttributeKind, StageBoardDefaultAttributesKind } from "api/manufacturing/models";

type SectionTitle = string;
const getSortedManufacturingUnitGroups = <
  T extends {
    startedAt: ISODateTime;
    finishedAt: ISODateTime;
    priority: ManufacturingUnitGroupPriority;
  }
>(
  units: T[] | null,
  key: "startedAt" | "finishedAt",
): (SectionTitle | T)[] => {
  if (!units) return [];
  const now = new Date();
  const todayUntil13 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0, 0);
  const todayUntil10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 0, 0);
  const todayMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

  const orderedUnits = units.reduce<Record<string, T[]>>(
    (acc, unit) => {
      if (new Date(unit[key]) > todayUntil13) {
        acc[""].push(unit);
      }
      if (new Date(unit[key]) < todayUntil13 && new Date(unit[key]) > todayUntil10) {
        acc["Dzisiaj do 13:00"].push(unit);
      }

      if (new Date(unit[key]) < todayUntil10 && new Date(unit[key]) > todayMidnight) {
        acc["Dzisiaj do 10:00"].push(unit);
      }
      if (dateFns.isYesterday(new Date(unit[key]))) {
        acc["Wczoraj"].push(unit);
      }
      Array.from(Array(5).keys())
        .map(day => day + 1)
        .forEach(day => {
          if (isSameDay(new Date(unit[key]), dateFns.subDays(now, day + 1))) {
            acc[`${dateFns.format(dateFns.subDays(now, day + 1), "d.MM.yyyy")}`].push(unit);
          }
        });

      if (isBefore(new Date(unit[key]), dateFns.subDays(now, 7))) {
        acc[" "].push(unit);
      }

      return acc;
    },
    {
      "": [],
      "Dzisiaj do 13:00": [],
      "Dzisiaj do 10:00": [],
      Wczoraj: [],
      [`${dateFns.format(dateFns.subDays(now, 2), "d.MM.yyyy")}`]: [],
      [`${dateFns.format(dateFns.subDays(now, 3), "d.MM.yyyy")}`]: [],
      [`${dateFns.format(dateFns.subDays(now, 4), "d.MM.yyyy")}`]: [],
      [`${dateFns.format(dateFns.subDays(now, 5), "d.MM.yyyy")}`]: [],
      [`${dateFns.format(dateFns.subDays(now, 6), "d.MM.yyyy")}`]: [],
      [`${dateFns.format(dateFns.subDays(now, 7), "d.MM.yyyy")}`]: [],
      " ": [],
    },
  );

  return Object.entries(orderedUnits)
    .filter(([, units]) => units.length)
    .map(([label, unitsSegment]) => [
      label,
      ...unitsSegment.sort((a, b) => {
        const priorityOrder = { A: 1, B: 2, C: 3, "": 4 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
      }),
    ])
    .flat();
};

const getTodoUnitColumnSearch = (query: QueryParams, stageId: UUID) =>
  queryString.stringify({
    masterStages: stageId,
    orderBy: "priority",
    pageSize: query.pageSize,
    priorities: ["A", "B"].join(","),
    stageStatus: "READY",
    isInGroup: "false",
    search: query.todoUnitsSearch,
  });

const getTodoGroupColumnSearch = (
  query: QueryParams,
  boardFormat: BoardFormat,
  attributesKind: StageBoardAttributeKind[],
) =>
  queryString.stringify({
    attributesKinds: attributesKind.join(","),
    groupByModel: manufacturingStagesConstants.groupByModelDict[boardFormat],
    search: query.todoGroupsSearch,
  });

const isLatestTicket = (allUnitsDates: Date[], ticketDate: ISODateTime): boolean => {
  return allUnitsDates.every(unitDate => !isAfter(unitDate, parseISO(ticketDate)));
};

const getAttributesKinds = (defaultFilters: StageBoardDefaultAttributesKind[]) => {
  return defaultFilters.map(defaultFilter => defaultFilter.attributeKind);
};

const hasFabric = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.FABRIC,
  );
const hasSize = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.SIZE,
  );
const hasSide = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.SIDE,
  );

const isUnitUrgent = (priority: ManufacturingUnitGroupPriority): boolean =>
  priority === ManufacturingUnitGroupPriority.A || priority === ManufacturingUnitGroupPriority.B;

const isUnitCritical = (priority: ManufacturingUnitGroupPriority): boolean =>
  priority === ManufacturingUnitGroupPriority.A;

const areSomeMaterialsNotOrdered = (
  customOrderedMaterials: ManufacturingUnitListViewItem["materials"],
): boolean => {
  if (customOrderedMaterials.some(material => !Boolean(material.orders.length))) return true;
  return customOrderedMaterials.some(material =>
    material.orders.some(order => order.status === MaterialStageStatus.NOT_ORDERED),
  );
};

const areSomeMaterialsOrdered = (
  customOrderedMaterials: ManufacturingUnitListViewItem["materials"],
): boolean => {
  return customOrderedMaterials.some(material =>
    material.orders.some(order => order.status === MaterialStageStatus.ORDERED),
  );
};

const normalizeTodoGroupedUnits = (
  elements: ManufacturingGroup["elements"],
): NormalizedGroupElement[] => {
  return elements.map(element => ({
    id: element.id,
    attributes: element.attributeValues.map(attribute => attribute.value.name).join(", "),
    attributeValues: [],
    isCancelled: element.isCancelled,
    isDeclined: element.isDeclined,
    manufacturingItemId: element.manufacturingItemId,
    note: element.note,
    orderSignature: element.orderSignature,
    priority: element.priority,
    signature: element.signature,
    externalOrderNumber: element.externalOrderNumber,
  }));
};

const normalizeInProgressGroupedUnits = (
  groupedUnits: GroupedUnitInProgress[],
): NormalizedGroupElement[] => {
  return groupedUnits.map(groupedUnit => ({
    id: groupedUnit.id,
    attributes: groupedUnit.attributes
      .filter(attribute => attribute.category === AttributeCategory.FABRIC)
      .map(attribute => attribute.value.name)
      .join(", "),
    attributeValues: [],
    isCancelled: groupedUnit.isCancelled,
    manufacturingItemId: groupedUnit.manufacturingItem.id,
    isDeclined: groupedUnit.isDeclined,
    note: groupedUnit.note,
    orderSignature: groupedUnit.order?.signature || null,
    priority: groupedUnit.priority,
    signature: groupedUnit.signature,
    externalOrderNumber: groupedUnit.manufacturingItem.externalOrderNumber,
  }));
};

export const manufacturingStagesUtils = {
  areSomeMaterialsNotOrdered,
  areSomeMaterialsOrdered,
  getAttributesKinds,
  getSortedManufacturingUnitGroups,
  getTodoUnitColumnSearch,
  getTodoGroupColumnSearch,
  hasFabric,
  hasSide,
  hasSize,
  isLatestTicket,
  isUnitCritical,
  isUnitUrgent,
  normalizeInProgressGroupedUnits,
  normalizeTodoGroupedUnits,
};
